import * as models from "./models-buffers";
import {iinop, ifblnk, buildURL}  from "../argitekjs/src/base"
import { SecurityManager } from "./sec_manager";
import { number } from "yup/lib/locale";

export {NodeMover, NodeSizer} from  "../argitekjs/src/ui";

export interface GlobalParameter  {    
  name: string;      
  value: string;     
}

declare global {
  interface Window {
    utils_globalParameters: Array<GlobalParameter>;
  }
}

if (!window.utils_globalParameters) {
  window.utils_globalParameters = [];
}



export function getParam(pName :string) : string {
  for (let i = 0; i < window.utils_globalParameters.length; i++) {
    if (window.utils_globalParameters[i].name == pName) {
      return window.utils_globalParameters[i].value;
    }
  }
  return "";
}

export function setParam(pName :string, pValue :string) : boolean {
  for (let i = 0; i < window.utils_globalParameters.length; i++) {
    if (window.utils_globalParameters[i].name == pName) {
      window.utils_globalParameters[i].value = pValue;
      return true;
    }
  }
  let param : GlobalParameter = {name:pName, value: pValue};
  window.utils_globalParameters.push(param);
  return false;
}

export async function GetSetting(pName : string): Promise<models.i_GeneralSetting> {
  let resp : models.i_GeneralSetting = {}

  let head = new Headers();
  head.set("x-fieldfilter-name",pName)
  head.set("x-custom-sql-w","status > 0")

  let req = new Request(`/api/generalsetting`,
    { method: "GET", 
      headers: head, 
    } 
  );

  let response = await fetch(req);
  if (response.status >= 200 && response.status <= 220) {
    let data = await response.json();
    if (data && data[0]) {
      resp = data[0];
    } else if (data && data.p_errmsg && data.p_retval > 0) {
      throw data.p_errmsg
    }
    else if (data) {
      resp = data;
    }
  } else {
    throw "Server error code: " + response.statusText ;
  }

  return resp;
}

export function prettySize(pBytes : number) : String {
  let unit : Number = 0;
  if (pBytes > 1000000000) { 
    unit = Math.round(pBytes / 1000000000.0);
    return `${unit}GB`;
  } else if (pBytes > 1000000) { 
    unit = Math.round(pBytes / 1000000.0);
    return `${unit}MB`;
  } else if (pBytes > 1000) { 
    unit = Math.round(pBytes / 1000.0) ;
    return `${unit}KB`;
  }
  
  return `${unit}b`;
}

export async function showHelpPage(pEvent : MouseEvent, pTemplateName : string) {
  
  try {
    if (pEvent.target instanceof HTMLElement) {
      pEvent.target.classList.add('actioned');
    }
    const url = new URL(window.location.href);
    const sup = getParam("UserLevel") == "2";
    let helppage : models.contenthelp;
    let setupmode : boolean = pEvent.ctrlKey  && sup;
    const windowSecManager = (window as any)._sec_manager as SecurityManager;

    if (windowSecManager && windowSecManager.active && sup) {
      setupmode = true;
    }

    let path = url.pathname;
    //path = path.replace(/blue/g, "red");

    let head = new Headers();
    head.append("x-not-select-fields", "document");
    head.append("x-fieldfilter-location", path);
    head.append("x-fieldfilter-status", "1");
    

    let req = new Request(getParam("RelURL") + "/contenthelp/api", {method: "GET", headers: head});

    let response = await fetch(req);
    if (response.status == 200) { 
      let payload = await response.json();
      helppage = payload[0];
      if (helppage && helppage.id > 0) {
        if (setupmode) {
          let previewURL = buildURL(getParam("RelURL") + "/contenthelp/form/" + helppage.id, {
            "skel":"1",
            "formaction": "2"
          });
          // @ts-ignore 
          btDialog.showPage(previewURL, "Change Help",'<i class="fa fa-question-circle"></i>');
        }
        else if (iinop(helppage.mediatype,'pdf','application/pdf')) {
          let previewURL = buildURL(getParam("RelURL") + "/docbyid/contenthelp/" + helppage.id, {
            "inline":"inline",
            "skel":"1"
          });
          //Todo: Rewrite using new dialog
          // @ts-ignore 
          btDialog.showPage(previewURL, "Help: "  + helppage.description,'<i class="fa fa-question-circle"></i>');
        } else {
          // @ts-ignore 
          MsgBox(helppage.body, 'Help: ' + helppage.description,'<i class="fa fa-question-circle"></i>','btnok');          
        }
      } else {
        if (setupmode) {
          let previewURL = buildURL(getParam("RelURL") + "/contenthelp/form" , {
            "skel":"1",
            "formaction": "1",
            "ps-location": url.pathname,
            "ps-name": getParam("TemplateName") + " Help",
            "ps-status": "1"
          });
          // @ts-ignore 
          btDialog.showPage(previewURL, "Change Help",'<i class="fa fa-question-circle"></i>');
        } else {
          // @ts-ignore 
          MsgBox('No help available for this topic.', 'Help: Not found','<i class="fa fa-question-circle"></i>','btnok');
        }
      }
    }

  } finally {
    if (pEvent.target instanceof HTMLElement) {
      pEvent.target.classList.remove('actioned');
    }
  }
  
}



export interface ContactUsProps {
  formaction: number //1=insert,2=update,3=delete
  dosubmit: number, //1=insert,2=update,3=delete
  firstnames: string,
  lastname: string,
  email: string,
  cellphone: string,
  subject: string,
  note: string,
}

export interface ContactUsResponseProps {
  retval: number,
  errmsg: string,
  payload? : string
}

export async function postContactUs(pData : ContactUsProps) : Promise<ContactUsResponseProps> {
    pData.formaction = 1;
    pData.dosubmit = 1;
    let postURL = buildURL(getParam("RelURL") + "/contactus/customform" , {
      "apicall":true,
    });

    let head = new Headers();
    let formbody = new FormData()
    formbody.set("dosubmit",String(pData.dosubmit))
    formbody.set("formaction",String(pData.formaction))
    formbody.set("cellphone",String(pData.cellphone))
    formbody.set("email",String(pData.email))
    formbody.set("firstnames",String(pData.firstnames))
    formbody.set("lastname",String(pData.lastname))
    formbody.set("note",String(pData.note))
    formbody.set("subject",String(pData.subject))

    let req = new Request(postURL, {method: "POST", headers: head, body:formbody});
    
    let response = await fetch(req);
    if (response.status >= 200 && response.status < 300) { 
      let payload = await response.json();
      console.log("Payload", payload);
      if (payload.retval > 0) {
        return {errmsg:payload.errmsg, retval:1} ;
      }
      return {errmsg:"", retval:0, payload: payload};
    }

   return {errmsg:"", retval:0}; 
}


export function isLoggedIn() : boolean{
  let userid = getParam('UserID');
  if (userid && userid != "" && userid != "0" ) {
    return true;
  }
  return false;
}

export function userLevel() : Number {
  let userlvl = getParam('UserLevel');
  if (userlvl && userlvl != ""  ) {
    return Number(userlvl);
  }
  return 0;
}

export const printHTML = (pSrc:string | Element,pId : string) => {
  let objFra : HTMLElement | undefined = document.querySelector("#docIframe"+pId);

  if (!objFra) {
    objFra = document.createElement('iframe');
  }

  if (objFra) {
    objFra.style.visibility = 'hidden'; 
    objFra.id = "docIframe"+pId;
    objFra.src = pSrc;
    document.body.appendChild(objFra);
    objFra.contentWindow.focus();
    objFra.contentWindow.print();
  }
}